.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0 6px;
  grid-auto-rows: minmax(min-content, max-content);
  text-align: center;
}

.image-list img {
  width: 100%;
  height: auto;
  text-align: center;
  border: 1px solid #e7dede;
}

.my-masonry-grid div {
  max-width: 250px;
  display: flex;
  margin: 5px;
}

.imgA {
  width: 100%;
}
